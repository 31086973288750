import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

const styles = () => {
  const size: string = '0.375rem'
  return createStyles({
    root: {
      width: size,
      height: size,
      backgroundColor: '#E2E8F0',
      borderRadius: '50%',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Dot = ({ classes }: Props) => {
  return <div className={classes.root} />
}

export default withStyles(styles)(Dot)
