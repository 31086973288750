import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import BaseSlider, { Settings } from 'react-slick'
import Dot from './Dot'

const styles = ({
  palette: {
    primary: { main: mainColor },
  },
}: Theme) => {
  return createStyles({
    root: {
      paddingBottom: '1.25rem',
      '& .slick-dots': {
        bottom: '-3.1875rem',

        '& > li': {
          width: 'auto',
          margin: '0 0.1875rem',
        },
        '& > .slick-active > div': {
          width: '1.25rem',
          backgroundColor: mainColor,
          borderRadius: '6.25rem',
        },
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, Settings {
  children: React.ReactNode
}

const Slider = ({ classes, children, ...other }: Props) => {
  const getCustomDot = () => <Dot />

  return (
    <div className={classes.root}>
      <BaseSlider
        slidesToShow={1}
        dots={true}
        arrows={false}
        customPaging={getCustomDot}
        {...other}
      >
        {children}
      </BaseSlider>
    </div>
  )
}

export default withStyles(styles)(Slider)
